/* eslint-disable react/no-unknown-property */

import {
  Plane,
  Sphere,
  Text,
  TorusKnot,
  useAnimations,
  useGLTF,
  useHelper,
} from "@react-three/drei";
import { applyProps, SpotLightProps } from "@react-three/fiber";
import { Instance } from "@react-three/fiber/dist/declarations/src/core/renderer";
import { folder, useControls } from "leva";
import {
  MutableRefObject,
  Suspense,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Group, LoopOnce, SpotLight, SpotLightHelper } from "three";
import { AnimationTrigger } from "./RecordedEvent";
import { GameState } from "./ReplayModel";

type ModelProps = {
  stadiumPath: string;
  gameState?: MutableRefObject<GameState>;
};

export const Stadium = ({ stadiumPath, gameState }: ModelProps) => {
  const { nodes, animations } = useGLTF(stadiumPath);
  const stadiumRef = useRef<Group>(null);
  const scoreRef = useRef<Group>(null);
  const dirGroupRef = useRef<Group>(null);
  const [score, setScore] = useState<string>("0 - 0");
  const { actions } = useAnimations(animations, stadiumRef);

  useEffect(() => {
    Object.keys(actions).forEach((key) => {
      actions[key]?.play();
    });
  }, [actions]);

  useEffect(() => {
    if (!gameState) return;
    setScore(
      `${gameState.current.score.teamA} - ${gameState.current.score.teamB}`
    );
  }, [scoreRef, gameState?.current.score, gameState]);

  useEffect(() => {
    if (nodes && scoreRef.current) {
      scoreRef.current.position.copy(nodes.Scoreboard.position);
      scoreRef.current.position.setZ(scoreRef.current.position.z + 0.21);
    }
  }, [nodes, scoreRef, stadiumRef]);

  useLayoutEffect(() => {
    // enable the shadows, they might not be present in the blender export?
    nodes.Scene.traverse((obj) => {
      // HACKERDYHACK
      const o = obj as unknown as Instance;
      if (o.isMesh) {
        applyProps(o, {
          castShadow: true,
          receiveShadow: true,

          "material-envMapIntensity": 0.2,
        });
      }
    });
  }, [nodes.Scene]);

  const [{ moon_helper, moon_radius, moon_samples }, set] = useControls(() => ({
    Moon: folder({
      moon_helper: { value: true },

      moon_radius: {
        value: 4,
        min: 0,
        max: 50,
        step: 0.1,
      },
      moon_samples: {
        value: 8,
        min: 1,
        max: 25,
        step: 1,
      },
    }),
  }));

  const spotLightRef = useRef<SpotLight>(null!);
  const moonHelper = useHelper(spotLightRef, SpotLightHelper, "blue");
  if (moonHelper.current) {
    moonHelper.current.visible = moon_helper;
  }

  console.log(spotLightRef.current);
  // const p: SpotLightProps;
  return (
    <>
      {/* MOON */}
      <spotLight
        ref={spotLightRef}
        castShadow
        color={0xff8888}
        angle={Math.PI / 5}
        penumbra={0.3}
        position={[8, 10, 5]}
        shadow-camera-near={8}
        shadow-camera-far={200}
        shadow-mapSize-width={256}
        shadow-mapSize-height={256}
        shadow-bias={-0.002}
        shadow-radius={moon_radius}
        shadow-blurSamples={moon_samples}
      />
      {/* <group ref={dirGroupRef}>
        <directionalLight
          castShadow
          color={0x8888ff}
          position={[3, 12, 17]}
          shadow-camera-near={0.1}
          shadow-camera-far={500}
          shadow-camera-right={17}
          shadow-camera-left={-17}
          shadow-camera-top={17}
          shadow-camera-bottom={-17}
          shadow-mapSize-width={512}
          shadow-mapSize-height={512}
          shadow-radius={4}
          shadow-bias={-0.0005}
        />
      </group> */}

      <TorusKnot scale={0.5} position-y={1} castShadow receiveShadow>
        <meshPhongMaterial color="0x999999" shininess={0} specular={0x222222} />
      </TorusKnot>

      <Plane
        args={[200, 200]}
        scale={3}
        rotation-x={-Math.PI / 2}
        castShadow
        receiveShadow
      >
        <meshPhongMaterial color="0x999999" shininess={0} specular={0x111111} />
      </Plane>
      {/* <primitive ref={stadiumRef} object={nodes.Scene} receiveShadow={true} /> */}
    </>
  );
};
