import { Suspense, useRef } from "react";

import { Canvas, useFrame } from "@react-three/fiber";
import { Group, SpotLight, Vector3 } from "three";

import {
  Environment,
  PerspectiveCamera,
  Stats,
  GizmoHelper,
  GizmoViewport,
  Text,
} from "@react-three/drei";
import { CustomControls } from "../CustomControls/CustomControls";
import { useAppContext } from "../../context/AppContext";
import { Replay } from "../Replay/Replay";

// import eventData from "../Replay/replayEvents.json";
import eventData from "../Replay/replay_new.json";
import walkData from "../Replay/walkSquare.json";
import { Stadium } from "../Replay/Stadium";
import { initialState } from "../Replay/ReplayModel";

const startPosCamera = new Vector3(0, 5.151436771522862, 63.77854941000632);

type WorldProps = {};
const World = ({}: WorldProps) => {
  const { resetCamera, setResetCamera } = useAppContext();
  const routeGroups = useRef<Group>(null);
  // const [resetCam, setResetCam] = useState<boolean>(false);
  // const directionalLightRef = useRef<DirectionalLight>(null!);
  // useHelper(directionalLightRef, DirectionalLightHelper, 1, "red");

  useFrame(({ camera, clock }) => {
    if (!routeGroups.current) return;
    // const scale = Math.min(1, window.innerWidth / window.innerHeight);
    // routeGroups.current.scale.set(scale, scale, scale);
  });
  const onResetCamera = () => {
    setResetCamera(true);
  };
  const onResetCameraDone = () => {
    // setResetCamera(false);
  };

  return (
    <>
      <fog attach="fog" color="hotpink" near={1} far={10} />
      {/* <Environment preset="city" /> */}
      <ambientLight color={0x444444} />
      {/* EFFECTS  */}
      {/*
          Bloom: https://codesandbox.io/s/bloom-hdr-workflow-gnn4yt?file=/src/App.js:552-754
       */}
      <GizmoHelper
        alignment="bottom-right" // widget alignment within scene
        margin={[80, 80]} // widget margins (X, Y)
      >
        <GizmoViewport
          axisColors={["red", "green", "blue"]}
          labelColor="black"
        />
      </GizmoHelper>

      {/* <gridHelper args={[100, 100]} /> */}

      <CustomControls
        resetPosition={resetCamera}
        onResetDone={onResetCameraDone}
      />
      {/* <PerspectiveCamera makeDefault position={startPosCamera} /> */}
      <PerspectiveCamera makeDefault position={[0, 10, 30]} />

      <group receiveShadow ref={routeGroups}>
        <group position={[0, 0, 0]}>
          <Stadium stadiumPath={"/stadium/Family_Zombie.glb"} />

          {/* <Replay
            stadium="/stadium/Family_Zombie.glb"
            replayData={eventData}
          /> */}
        </group>
      </group>
    </>
  );
};
type ModelProps = {};

export const ThreeD = () => {
  return (
    // <Canvas camera={{ fov: 50 }} dpr={[1, 2]}>
    <>
      <Canvas shadows dpr={[1, 2]}>
        <Suspense fallback={null}>
          <World />
        </Suspense>
        <Stats />
      </Canvas>
    </>
  );
};
