import { forwardRef, useEffect, useRef, useState } from "react";

import { useThree } from "@react-three/fiber";
import { Vector3 } from "three";
//@ts-ignore
import { gsap } from "gsap/dist/gsap";
import { OrbitControls } from "@react-three/drei";

type ControlsProps = { resetPosition: boolean; onResetDone: () => void };
export const CustomControls = forwardRef(
  ({ resetPosition, onResetDone }: ControlsProps, ref) => {
    const [startPositionCamera, setStartPositionCamera] = useState<Vector3>(
      new Vector3()
    );
    const [startPositionTarget, setStartPositionTarget] = useState<any>();
    const { gl, camera } = useThree();
    const controls = useRef<any>(null);

    // useEffect(() => {
    //   if (controls.current && camera) {
    //     setStartPositionCamera(camera.position.clone());
    //     setStartPositionTarget(controls.current.target);
    //   }
    // }, [controls, camera]);

    // useEffect(() => {
    //   if (controls.current && resetPosition) {
    //     gsap
    //       .timeline()

    //       .to(camera.position, {
    //         x: startPositionCamera.x,
    //         y: startPositionCamera.y,
    //         z: startPositionCamera.z,
    //         ease: "elastic.out(1, 0.63)",
    //       })
    //       .to(
    //         controls.current.target,
    //         {
    //           x: startPositionTarget.x,
    //           y: startPositionTarget.y,
    //           z: startPositionTarget.z,

    //           onComplete: () => {
    //             onResetDone();
    //           },
    //         },
    //         "<"
    //       )
    //       .totalDuration(2.3);
    //   }
    // }, [resetPosition]);

    return (
      <OrbitControls
        // enableZoom={false}
        // enablePan={false}
        // maxPolarAngle={Math.PI / 2}
        // minPolarAngle={Math.PI / 2.2}
        makeDefault={true}
        enabled={true}
        ref={controls}
        // onEnd={(e) => handleChange(e)}
      />
    );
  }
);
